<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">一人一档列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入机构名称"
                clearable
              />
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                min-width="180"
                show-overflow-tooltip
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                min-width="180"
                show-overflow-tooltip
              />
              <el-table-column
                label="企业名称"
                align="left"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.enterpriseName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                width="260"
                show-overflow-tooltip
              />
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaNamePath"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="行业类型"
                align="left"
                prop="industryName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="岗位类型"
                align="left"
                prop="postName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="职业工种"
                align="left"
                prop="occupationName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训等级"
                align="left"
                prop="trainLevelName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      handStudentFile(scope.row.projectId, scope.row.hasCache)
                    "
                    >学员档案</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlEnterpriseArchives(scope.row)"
                    >下载首页</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlDownloadFiles(scope.row)"
                    >下载档案</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="archivesList.length"
            >
              <el-form-item v-for="(item, index) in archivesList" :key="index">
                <el-checkbox v-model="docsFormnew[item.dictCode]" checked>{{
                  item.dictValue
                }}</el-checkbox>
                <el-radio-group
                  v-model="radio"
                  v-if="item.dictValue == '学时证明' && !hasCache"
                >
                  <el-popover
                    placement="bottom"
                    title="学时证明模板一"
                    width="450"
                    trigger="click"
                  >
                    <img
                      src="../../assets/templateOne.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <button
                      @click="radio = '1'"
                      style="background-color: transparent; border: none"
                      slot="reference"
                    >
                      <el-radio label="1">学时证明模板一</el-radio>
                    </button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    title="学时证明模板二"
                    width="450"
                    trigger="click"
                  >
                    <img
                      src="../../assets/templateTwo.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <button
                      @click="radio = '2'"
                      style="background-color: transparent; border: none"
                      slot="reference"
                    >
                      <el-radio label="2">学时证明模板二</el-radio>
                    </button>
                  </el-popover>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div style="margin: 10px 3%">
              <el-checkbox v-model="rebuild">重新下载档案</el-checkbox>
            </div>
          </div>

          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "OnepersonOneFile",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectCode:"", // 班级编码
      projectName: "", //班级名称
      compName: "", //题库名称
      enterpriseName: "",
      docsDialogVisible: false,
      archivesList: [],
      docsFormnew: {},
      agreeState: false,
      radio: "1",
      hasCache: false,
      rebuild: false,
      projectNames: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.projectCode){
        params.projectCode = this.projectCode;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.enterpriseName) {
        params.enterpriseName = this.enterpriseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.doFetch({
        url: "/eighteenEnterprise/enterpriseUser/pageList",
        params,
        pageNum,
      });
    },
    /* 学员档案 */
    handStudentFile(projectId, hasCache) {
      this.$router.push({
        path: "/web/OnepersonStudentFiles",
        query: {
          projectId,
          hasCache,
        },
      });
    },
    /* 下载首页 */
    handlEnterpriseArchives(row) {
      let params = {
        fileDTOS: [],
      };

      params.fileDTOS.push({
        fileType: "30",
        fileName: row.projectName + "-首页",

        param: {
          docType: "400",
          examineTypes: ["10"],
          projectId: row.projectId,
        },
      });
      this.$post(
        "/eighteenEnterprise/enterpriseUser/dmAsyncDownload",
        params
      ).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    /* 下载档案 */
    handlDownloadFiles(row) {
      this.docsDialogVisible = true;
      this.projectId = row.projectId;
      this.projectNames = row.projectName;
      this.hasCache = row.hasCache;
      this.getFiles(row.projectId);
    },
    getFiles(projectId) {
      this.$post("/eighteenEnterprise/enterpriseUser/userCertList", {
        projectId,
      })
        .then((ret) => {
          this.archivesList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.archivesList = [];
      this.rebuild = false;
      //  this.$refs["docsFormnew"].resetFields();
      // for (let key in this.docsFormnew) {
      //   this.docsFormnew[key] = true;
      // }
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    confirmDocsDialog() {
      // console.log(this.trainTypeId);
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      let examineTypes = [];
      for (let i in this.docsFormnew) {
        console.log(i);
        console.log(this.docsFormnew[i]);
        if (this.docsFormnew[i]) {
          examineTypes.push(
            this.archivesList.find((el) => el.dictCode == i).dictCode
          );
        }
      }
      params.fileDTOS.push({
        fileType: "30",
        fileName: this.projectNames + "-培训档案",
        param: {
          docType: "410",
          examineTypes: examineTypes,
          projectId: this.projectId,
          modeType: this.radio,
          rebuild: this.rebuild,
        },
      });
      this.$post(
        "/eighteenEnterprise/enterpriseUser/dmAsyncDownload",
        params
      ).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    changeRadio(val) {
      console.log(val);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.el-radio-group {
  > span {
    margin: 0 0.25rem;
  }
}
</style>
